const apiData = [
  {
    name: "Get All Employees",
    requestUrl: {
      URL: "/api/employees", response: [
        {
          total: 10,
          total_pages: 1,
          employees: [
            {
              id: 1,
              firstName: "John",
              lastName: "Doe",
              email: "johndoe@example.com",
              role: "Software Engineer",
              location: "London",
              salary: 85000,
            },
            {
              id: 2,
              firstName: "Jane",
              lastName: "Smith",
              email: "janesmith@example.com",
              role: "Software Engineer",
              location: "India",
              salary: 90000,
            },
            {
              id: 3,
              firstName: "Emily",
              lastName: "Johnson",
              email: "emilyjohnson@example.com",
              role: "Product Manager",
              location: "San Francisco",
              salary: 95000,
            },
            {
              id: 4,
              firstName: "Michael",
              lastName: "Brown",
              email: "michaelbrown@example.com",
              role: "QA Engineer",
              location: "Sent Loi",
              salary: 78000,
            },
            {
              id: 5,
              firstName: "Jessica",
              lastName: "Wilson",
              email: "jessicawilson@example.com",
              role: "QA Engineer",
              location: "Chicago",
              salary: 80000,
            },
            {
              id: 6,
              firstName: "David",
              lastName: "Taylor",
              email: "davidtaylor@example.com",
              role: "HR Manager",
              location: "London",
              salary: 60000,
            },
            {
              id: 7,
              firstName: "Daniel",
              lastName: "Anderson",
              email: "danielanderson@example.com",
              role: "Frontend Developer",
              location: "London",
              salary: 85000,
            },
            {
              id: 8,
              firstName: "Sarah",
              lastName: "Martinez",
              email: "sarahmartinez@example.com",
              role: "Backend Developer",
              location: "San Francisco",
              salary: 87000,
            },
            {
              id: 9,
              firstName: "Robert",
              lastName: "Thomas",
              email: "robertthomas@example.com",
              role: "Product Manager",
              location: "San Francisco",
              salary: 96000,
            },
            {
              id: 10,
              firstName: "Laura",
              lastName: "Garcia",
              email: "lauragarcia@example.com",
              role: "Marketing Specialist",
              location: "Chicago",
              salary: 65000,
            },
          ],
        },
      ],
    },
    requestType: "GET",
    responseCode: 200,
  
  },
  {
    name: "Get single Employee By Id",
    requestUrl: {
      URL: "/api/employee/1", response: {
        id: 1,
        firstName: "John",
        lastName: "Doe",
        email: "johndoe@example.com",
        role: "Software Engineer",
        location: "London",
        salary: 85000,
      }
    },
    requestType: "GET",
    responseCode: 200,
  },
  {
    name: "Get Employees By roles",
    requestUrl: {
      URL: "/api/employees?role=Software Engineer", response: {
        "total": 2,
        employees: [
          {
            id: 1,
            firstName: "John",
            lastName: "Doe",
            email: "johndoe@example.com",
            role: "Software Engineer",
            location: "London",
            salary: 85000,
          },
          {
            id: 2,
            firstName: "Jane",
            lastName: "Smith",
            email: "janesmith@example.com",
            role: "Software Engineer",
            location: "London",
            salary: 90000,
          },
        ],
      },
    },
    requestType: "GET",
    responseCode: 201,

  },
  {
    name: "Get Employees By roles and Locations",
    requestUrl: {
      URL: "/api/employees?role=QA Engineer&location=Chicago", response: {
        employees: [
          {
            id: 4,
            firstName: "Michael",
            lastName: "Brown",
            email: "michaelbrown@example.com",
            role: "QA Engineer",
            location: "London",
            salary: 78000,
          },
          {
            id: 5,
            firstName: "Jessica",
            lastName: "Wilson",
            email: "jessicawilson@example.com",
            role: "QA Engineer",
            location: "London",
            salary: 80000,
          },

        ]
      },
    },
    requestType: "GET",
    responseCode: 202,
  },
  {
    name: "Create Employee",
    requestUrl: {
      URL: "/api/employee/create",
      response: {
        id: 11,
        firstName: "Alice",
        lastName: "Johnson",
        email: "alicejohnson@example.com",
        role: "QA Engineer",
        location: "London",
        salary: 75000,
        message: "Employee created successfully.",
      }
    },
    responseCode: 201,
    requestType: "POST",
    request: {
      body: {
        firstName: "Alice",
        lastName: "Johnson",
        email: "alicejohnson@example.com",
        role: "QA Engineer",
        location: "London",
        salary: 75000,
      },
    },

  },
  {
    name: "Update Employee",
    requestUrl: {
      URL: "/api/employee/update/11", response: {
        id: 11,
        firstName: "Alice",
        lastName: "Johnson",
        email: "alicejohnson@example.com",
        role: "Senior QA Engineer",
        location: "London",
        salary: 80000,
        message: "Employee updated successfully.",
      },
    },
    requestType: "PUT",
    request: {
      pathParameter: { id: 11 },
     
      body: {
        firstName: "Alice",
        lastName: "Johnson",
        email: "alicejohnson@example.com",
        role: "Senior QA Engineer",
        location: "London",
        salary: 80000,
      },
    },
    responseCode: 200,

  },
  {
    name: "Update Employee Partially ",
    requestUrl: {
      URL: "/api/employee/update/11", response: {
        id: 11,
        firstName: "Alice",
        lastName: "Johnson",
        email: "alicejohnson@example.com",
        role: "Senior QA Engineer",
        location: "London",
        salary: 82000,
        message: "Employee salary updated successfully.",
      },
    },
    requestType: "PATCH",
    request: {
      pathParameter: { id: 11 },
     
      body: { salary: 82000 },
      
    },
    responseCode: 200,

  },
  {
    name: "Delete Employee",
    requestUrl: { URL: "/api/employee/2", response: null, },
    requestType: "DELETE",

    responseCode: 204,
    // No content response
  },
  {
    name: "Get Asset Using Bearer Token",
    requestUrl: {
      URL: "/api/employee/6/assets",
      response: {

        assetName: "Laptop",
        assetType: "Electronics",
        assignedDate: "2024-01-15",
        status: "Assigned",
      }
    },
   
    requestType: "GET",
    request: {
      headers: {Authorization: "Bearer LearnWithShelendra"},
    },
    responseCode: 200,

  },
  {
    name: "Create Asset Using Basic Authentication",
    requestUrl: {
      URL: "/api/employee/10/assets/add",
      response: {
        assetName: "Car",
        assetType: "Vehicle",
        assignedDate: "2024-10-01",
        status: "Assigned",
      }
    },
    requestType: "POST",
    request: {
      headers: {
        Authorization: { 
          Username: "Admin", 
          Password: "Admin123"
        },
        
       
      },
      body: {
        assetName: "Car",
        assetType: "Vehicle",
        assignedDate: "2024-10-01",
        status: "Assigned",
      },
      responseCode: 201
    },

  },
  {
    name: "Delete Asset Using API Key",
    requestUrl: {
      URL: "/api/employee/123/assets/remove/234",
      response: {
        message: "Asset removed from employee successfully.",
      },
    },
    requestType: "DELETE",
 
    request: {
      headers: {Authorization: "Basic 2a3e6f7a93e4f3a8b98f2e1c24ef5f8a"},
    },
    responseCode: 200,

  },
];

module.exports = apiData;
