import React from 'react';
import '../styles/Navbar.css';


const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* External link for Selenium Practice */}
        <a 
          href="https://practice.techrequestresponse.com/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="navbar-link"
        >
          <h1>SeleniumPractice</h1>
        </a>

        {/* Main logo */}
        {/* <h1 className="navbar-logo">Tech Request Response</h1> 

        {/* Subtitle */}
        {/* <h2 className="navbar-title">API Learning Platform</h2>  */}
      </div>
    </nav>
  );
};

export default Navbar;
